import { FC, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import * as Yup from "yup";
import classNames from "classnames";
import FormLabel from "../components/FormLabel";
import { useAppSelector } from "../store/hooks";
import LoadingIcon from "../components/LodingIcon";
import { WhitelistOption } from "./Shipments/ShipmentForm";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, FormikHelpers, FormikProps } from "formik";
import HorizontalPadding from "../components/HorizontalPadding";
import ShipmentPiecesDetails from "./Shipments/ShipmentPiecesDetails";
import { ClientDetails } from "../components/Shipment/ShipmentHeader";
import CreateClientModal from "../components/Account/CreateClientModal";
import UpdateClientModal from "../components/Account/UpdateClientModal";
import ShipmentItemTable from "../components/Shipment/ShipmentItemTable";
import {
  FormTextInput,
  FormGroupSearch,
  FormSimpleRadioGroup,
  FormTextAreaInput,
} from "../components/FormFields";
import {
  AccountSearch,
  BoxAccountSearch,
  filterDestination,
  AccountSearchFavoriteHistoric,
} from "../components/Account/AccountSearch";
import {
  Nullable,
  PaymentMode,
  discountItem,
  DeliveryType,
  ItemInterface,
  PieceInterface,
  ShipmentService,
  AccountInterface,
  LocationInterface,
  BoxAccountInterface,
  AccountAuthorization,
  DestinationInterface,
  BusinessUnitInterface,
  ShipmentInterface,
  ShipmentStatus,
} from "../interfaces";
import {
  formatName,
  validateAccount,
  paymentModeFormat,
  currencyExchangeText,
  useCurrencyExchanges,
  formatFloat,
} from "../utils";
import {
  getAccount,
  getShipmentItems,
  getShipmentRates,
  getAccountWhitelist,
  alertService,
  updateShipment,
  getBUCredit,
  getTier,
  getBusinessUnitByTerritory,
} from "../services";
import {
  AddressSearch,
  BusinessUnitSearch,
} from "../components/LocationSearch";
import {
  AddButton,
  PrimaryButton,
  SecondaryButton,
} from "../components/Buttons";
import { AutocompleteRegion } from "../components/Form/FormMapsAutocomplete";
import { BUCreditDTO } from "../interfaces/Dtos/BUCreditDTO";

interface ShipmentFormValue {
  shipper: Nullable<AccountInterface>;
  boxAccount: Nullable<BoxAccountInterface>;

  consignee: Nullable<AccountInterface>;
  buConsignee: Nullable<BusinessUnitInterface>;
  consigneeAddress: Nullable<LocationInterface>;

  accountBillTo: Nullable<AccountInterface>;

  discount: string;
  observations: string;
  items: ItemInterface[];
  pieces: PieceInterface[];
  totalChargedWeight: number;
  totalPhysicalWeight: number;
  totalDimensionalWeight: number;
  service: Nullable<ShipmentService>;
  paymentMode: Nullable<PaymentMode>;
  deliveryType: Nullable<DeliveryType>;
  total: number;
}
interface FormProps {
  formik: FormikProps<ShipmentFormValue>;
}
const Form: FC<FormProps> = ({ formik }) => {
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );
  const user = useAppSelector((state) => state.user);
  const countries = useAppSelector((state) => state.inmutable.countries);
  const buPaymentModes = useAppSelector((state) => state.user.paymentModes);
  const maxDiscount = useAppSelector((state) => state.inmutable.maxDiscount);
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const pieceCategories = useAppSelector(
    (state) => state.inmutable.pieceCategories
  );
  const taxIdTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const rateKey = useRef<string>("");
  const itemsKey = useRef<string>("");
  const [isLoading, setIsLoading] = useState(0);
  const [buCredit, setBuCredit] = useState<BUCreditDTO>();
  const [whitelistSearch, setWhitelistSearch] = useState("");
  const [consigneeType, setConsigneeType] = useState("account");
  const [shipperAuth, setShipperAuth] = useState<AccountAuthorization>();
  const [whitelist, setWhitelist] = useState<DestinationInterface[]>([]);
  const [openShipperEditModal, setOpenShipperEditModal] = useState(false);
  const [shipmentItems, setShipmentItems] = useState<ItemInterface[]>([]);
  const [openConsigneeEditModal, setOpenConsigneeEditModal] = useState(false);
  const [openShipperCreationModal, setOpenShipperCreationModal] =
    useState(false);
  const [openConsigneeCreationModal, setOpenConsigneeCreationModal] =
    useState(false);
  const [baseShipmentItems, setBaseShipmentItems] = useState<ItemInterface[]>(
    []
  );

  const paymentModes = useMemo(() => {
    let result = buPaymentModes
      .filter(
        (mode) =>
          mode.paymentModeID !== PaymentMode.CREDIT &&
          mode.paymentModeID !== PaymentMode.DEST_BOXOFFICE_CREDIT &&
          (!!buCredit || mode.paymentModeID !== PaymentMode.BOXOFFICE_CREDIT)
      )
      .map((mode) => ({
        name: paymentModeFormat(mode.paymentModeID),
        value: mode.paymentModeID,
        disabled:
          mode.paymentModeID === PaymentMode.BOXOFFICE_CREDIT &&
          (buCredit?.balance ?? 0) <= 0,
        tooltip:
          mode.paymentModeID === PaymentMode.BOXOFFICE_CREDIT &&
          (buCredit?.balance ?? 0) <= 0
            ? "La tienda usó la totalidad del crédito disponible"
            : "",
      }));

    if (
      shipperAuth === AccountAuthorization.ENTERPRISE &&
      (!formik.values.shipper?.listAuthorizingAccount ||
        formik.values.shipper.listAuthorizingAccount.length === 0)
    ) {
      result.push({
        name: paymentModeFormat(PaymentMode.CREDIT),
        value: PaymentMode.CREDIT,
        disabled: false,
        tooltip: "",
      });
    }

    return result;

    // You only need to know the shipperAuth and if they have a boxAccount to
    // obtain the payment methods and the auth account
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperAuth, formik.values.shipper, formik.values.boxAccount, buCredit]);

  const paymentModesWithAuth = useMemo(() => {
    return [
      ...paymentModes,
      ...(formik.values.shipper?.listAuthorizingAccount ?? []),
    ].map((option) => {
      if ("value" in option) {
        return option;
      } else {
        return {
          name: "Crédito de " + formatName(option.accountFullName),
          value: option,
        };
      }
    });
  }, [paymentModes, formik.values.shipper?.listAuthorizingAccount]);

  const availableServiceTypes = useMemo(() => {
    if (consigneeType === "locker") {
      return [
        {
          name: "Casillero Nacional",
          value: ShipmentService.NATIONAL_LOCKER,
        },
      ];
    } else if (formik.values.deliveryType === DeliveryType.INTERNATIONAL) {
      return [
        {
          name: "Envío Internacional",
          value: ShipmentService.INTERNATIONAL,
        },
      ];
    } else {
      return [
        {
          name: "Envío Regular",
          value: ShipmentService.STANDARD,
        },
        {
          name: "Envío de Sobre",
          value: ShipmentService.DOCUMENT,
        },
        {
          name: "Envío Económico",
          value: ShipmentService.ECONOMIC,
        },
      ];
    }
  }, [consigneeType, formik.values.deliveryType]);

  const handlePaymentModeChange = (option: PaymentMode | string) => {
    if (option === PaymentMode.COD) {
      formik.setValues({
        ...formik.values,
        accountBillTo: formik.values.consignee,
        paymentMode: option,
      });
    } else if (option === PaymentMode.CONTADO) {
      formik.setValues({
        ...formik.values,
        accountBillTo: formik.values.shipper,
        paymentMode: option,
      });
    } else {
      const accountBillTo = paymentModesWithAuth.find(
        (p) => typeof p.value === "object" && p.value.id === option
      )?.value! as AccountInterface;

      formik.setValues({
        ...formik.values,
        paymentMode: PaymentMode.CREDIT,
        accountBillTo,
        consignee: null,
        consigneeAddress: null,
      });
    }
  };

  // Get shipper auth
  useEffect(() => {
    if (!formik.values.shipper) {
      setShipperAuth(AccountAuthorization.NO_AUTHORIZED);
      return;
    }

    if (!!formik.values.shipper.agreementID) {
      setShipperAuth(AccountAuthorization.ENTERPRISE);
      return;
    }
    // Check if there are some autorizers companies
    if (formik.values.shipper.listAuthorizingAccount.length === 0) {
      setShipperAuth(AccountAuthorization.NO_AUTHORIZED);
      return;
    }

    // If there is no elements in the shippers listAuthorizingAccount then, set AUTHORIZED
    setShipperAuth(AccountAuthorization.AUTHORIZED);
  }, [formik.values.shipper]);

  // Get consignee auth
  useEffect(() => {
    // Check if it's a locker: assing accountBillTo
    if (consigneeType === "locker") {
      formik.setValues({
        ...formik.values,
        accountBillTo: formik.values.consignee,
      });
    }
  }, [consigneeType, formik]);

  // Get whitelist from selected shipper
  useEffect(() => {
    let canceled = false;

    const getLocations = async () => {
      if (
        !formik.values.accountBillTo ||
        shipperAuth === AccountAuthorization.NO_AUTHORIZED ||
        !formik.values.accountBillTo.agreementID
      ) {
        setWhitelist([]);
        return;
      }

      const response = await getAccountWhitelist(
        taxIdTypes,
        formik.values.accountBillTo.id,
        formik.values.accountBillTo?.agreementID ?? undefined,
        false // isLocked = false - Only get whitelisted destinations
      );
      if (canceled) return;

      if (
        !response ||
        !response.model ||
        response.model.consigneeDestinationList.length === 0
      ) {
        setWhitelist([]);
        return;
      }

      setWhitelist(response.model.consigneeDestinationList);
    };

    getLocations();

    return () => {
      canceled = true;
    };
  }, [formik.values.accountBillTo, shipperAuth, taxIdTypes]);

  // Get shipment items
  useEffect(() => {
    const getAllShipmentItems = async () => {
      const key = v4();
      itemsKey.current = key;

      setIsLoading((p) => p + 1);
      const tier = await getTier({
        AccountBillToID: formik.values.accountBillTo?.id,
        LatitudeShipperAddress: user.businessUnit?.location.coordinates.lat,
        LongitudeShipperAddress: user.businessUnit?.location.coordinates.lng,
        LatitudeConsigneeAddress:
          formik.values.consigneeAddress?.coordinates.lat,
        LongitudeConsigneeAddress:
          formik.values.consigneeAddress?.coordinates.lng,
        ServiceID: formik.values.service!,
        PaymentModeID: formik.values.paymentMode!,
      });
      const response = await getShipmentItems({
        CurrencyID: 2,
        CountryID: 236,
        Weight: formik.values.totalChargedWeight,
        DeclaratedValue: formik.values.pieces?.reduce(
          (acc, p) => acc + p.value,
          0
        ),
        Distance: 0,
        ApplicationID: applicationID,
        ShipperAccountID: formik.values.shipper?.id,
        ConsigneeAccountID: formik.values.consignee?.id,
        AccountBillToID: formik.values.accountBillTo?.id,
        AccountSiteID: formik.values.accountBillTo?.accountSiteID ?? undefined,
        ServiceID: formik.values.service ?? undefined,
        PaymentModeID: formik.values.paymentMode ?? undefined,
        DeliveryTypeID: formik.values.deliveryType ?? undefined,
        SRM: !!formik.values.pieces?.reduce((acc, p) => acc + p.value, 0),
        TierID: tier.model?.tierID,
        ShippingMethodID: 10,
        PackageTypeID: 10,
        SalesDate: new Date().toISOString(),
        BUCodeSource: user.businessUnit?.code,
        BUCodeConsignee: formik.values.buConsignee?.code,
        IsToPickup: false,
      });
      setIsLoading((p) => p - 1);

      if (!response || key !== itemsKey.current) return;
      if (response.didError || !response.model) {
        return;
      }
      itemsKey.current = "";

      // Filter ipostel and discount
      setBaseShipmentItems(
        response.model.filter(
          (item) =>
            !item.name.toLowerCase().includes("ipostel") &&
            item.id !== discountItem.id
        )
      );
    };

    getAllShipmentItems();
  }, [
    formik.values.shipper?.id,
    formik.values.consignee?.id,
    formik.values.service,
    formik.values.paymentMode,
    formik.values.deliveryType,
    formik.values.pieces,
    formik.values.accountBillTo,
    formik.values.buConsignee,
    formik.values.consigneeAddress,
    formik.values.totalChargedWeight,
    applicationID,
    user.businessUnit,
    countries,
    pieceCategories,
  ]);

  // Get shipment items rate
  useEffect(() => {
    const { pieces, consigneeAddress, shipper } = formik.values;
    if (
      !pieces ||
      !shipper ||
      !consigneeAddress ||
      pieces.length === 0 ||
      baseShipmentItems.length === 0
    ) {
      setShipmentItems([]);
      formik.setFieldValue("items", []);
      return;
    }

    const getAllShipmentItems = async () => {
      const key = v4();
      rateKey.current = key;

      setIsLoading((p) => p + 1);
      let response = await getShipmentRates(
        formik.values.service ?? ShipmentService.STANDARD,
        formik.values.paymentMode ?? PaymentMode.COD,
        formik.values.deliveryType ?? DeliveryType.AT_OFFICE,
        user.businessUnit!.code ?? "", // buSource.code
        formik.values.buConsignee?.code ?? "",
        formik.values.shipper?.id,
        formik.values.consignee?.id,
        formik.values.accountBillTo?.id,
        user.businessUnit!.location,
        consigneeAddress,
        baseShipmentItems,
        pieces,
        pieces.reduce((acc, p) => acc + p.value, 0) > 0,
        new Date().toISOString(),
        applicationID,
      );
      setIsLoading((p) => p - 1);

      if (!response || key !== rateKey.current) {
        return;
      }
      if (response.didError || !response.model) {
        return;
      }
      rateKey.current = "";

      setShipmentItems(response.model.items);
      formik.setValues({
        ...formik.values,
        totalChargedWeight: response.model.chargedWeight,
        totalPhysicalWeight: response.model.physicalWeight,
        totalDimensionalWeight: response.model.dimensionalWeight,
        items: response.model.items
          .filter(
            (item) =>
              item.mandatory ||
              formik.values.items?.some((s) => s.id === item.id)
          )
          .sort((a, b) => a.order - b.order),
      });
    };

    getAllShipmentItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseShipmentItems,
    formik.values.shipper,
    formik.values.buConsignee,
    formik.values.consigneeAddress,
    formik.values.accountBillTo?.id,
  ]);

  // Form data dependencies

  useEffect(() => {
    let service: ShipmentService;

    if (consigneeType === "locker") {
      service = ShipmentService.NATIONAL_LOCKER;
    } else if (formik.values.deliveryType === DeliveryType.INTERNATIONAL) {
      service = ShipmentService.INTERNATIONAL;
    } else {
      service = ShipmentService.STANDARD;
    }

    formik.setFieldValue("service", service);
  }, [consigneeType, formik.values.deliveryType]);

  useEffect(() => {
    let newPaymentMode = formik.values.paymentMode;
    let accountBillTo: Nullable<AccountInterface> = formik.values.accountBillTo;

    // Get pay modality
    if (
      shipperAuth === AccountAuthorization.NO_AUTHORIZED &&
      !formik.values.boxAccount
    ) {
      newPaymentMode =
        formik.values.paymentMode !== PaymentMode.COD &&
        formik.values.paymentMode !== PaymentMode.CONTADO
          ? PaymentMode.COD
          : formik.values.paymentMode;
    } else if (shipperAuth === AccountAuthorization.ENTERPRISE) {
      if (!formik.values.boxAccount) {
        setConsigneeType("account");
      }
      newPaymentMode = PaymentMode.CREDIT;
    }

    // Get shipper auth account
    if (shipperAuth === AccountAuthorization.ENTERPRISE) {
      accountBillTo = formik.values.shipper;
    } else if (
      shipperAuth !== AccountAuthorization.AUTHORIZED &&
      formik.values.paymentMode === PaymentMode.COD
    ) {
      accountBillTo = formik.values.consignee;
    } else if (
      shipperAuth !== AccountAuthorization.AUTHORIZED &&
      formik.values.paymentMode === PaymentMode.CONTADO
    ) {
      accountBillTo = formik.values.shipper;
    } else if (
      // For initial case, when the shipper is authorized and the payment mode is COD, then force set credit
      shipperAuth === AccountAuthorization.AUTHORIZED &&
      formik.values.paymentMode === PaymentMode.CREDIT &&
      !!formik.values.shipper?.listAuthorizingAccount.length &&
      !formik.values.accountBillTo
    ) {
      accountBillTo = formik.values.shipper.listAuthorizingAccount[0];
    }

    // Update draft
    if (
      newPaymentMode !== formik.values.paymentMode ||
      accountBillTo?.id !== formik.values.accountBillTo?.id
    ) {
      formik.setValues({
        ...formik.values,
        paymentMode: newPaymentMode,
        accountBillTo,
      });
    }

    // You only need to know the shipperAuth and if they have a boxAccount to
    // obtain the payment methods and the auth account
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperAuth, formik.values.boxAccount, formik.values.paymentMode]);

  useEffect(() => {
    if (!formik.values.items) return;

    const absoluteTotal = formik.values.items
      .filter((item) => !item.rate.isPercentage)
      .reduce((total, item) => total + item.rate.value, 0);
    const percentageTotal = formik.values.items
      .filter((item) => item.rate.isPercentage)
      .reduce((total, item) => total + item.rate.value, 0);

    const total = absoluteTotal * (1 + percentageTotal / 100);

    formik.setFieldValue("total", total);
  }, [formik.values.items]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getCredit = async () => {
      const response = await getBUCredit(buCode);
      if (!response.didError && response.model) {
        setBuCredit(response.model);
      }
    };

    getCredit();
  }, [user.businessUnit]);

  return (
    <div className="flex flex-1 flex-col gap-6">
      <div className="flex flex-1 2xl:flex-row flex-col gap-4">
        {/* Search shipper */}
        <div className="relative flex flex-1">
          <AccountSearch
            title="Remitente"
            selected={formik.values.shipper ?? undefined}
            error={
              formik.touched.shipper && formik.errors.shipper
                ? formik.errors.shipper
                : ""
            }
            placeholder="Buscar remitente..."
            onSelectClient={async (shipper) => {
              const validations = await validateAccount(shipper, countries);

              formik.setValues({
                ...formik.values,
                shipper,
                buConsignee: null,
                consigneeAddress: null,
                boxAccount: null,
              });

              if (!!shipper && validations.error) {
                setOpenShipperEditModal(true);
              }
            }}
            openCreationModal={() => {
              setOpenShipperCreationModal(true);
            }}
          />
        </div>

        {/* Search consignee */}
        <div className="flex flex-1 flex-col">
          <div className="flex items-center justify-between mb-2">
            <label className="block text-m font-medium  text-gray-900 truncate">
              Destinatario
            </label>

            <FormSimpleRadioGroup
              horizontal
              name="consigneeType"
              disabled={
                !formik.values.shipper ||
                formik.values.paymentMode === PaymentMode.CREDIT
              }
              selected={consigneeType}
              onSelectOption={(option) => {
                formik.setValues({
                  ...formik.values,
                  buConsignee: null,
                  consignee: null,
                  consigneeAddress: null,
                  boxAccount: null,
                  deliveryType: DeliveryType.AT_OFFICE,
                });
                setConsigneeType(option);
              }}
              options={[
                { value: "account", name: "Persona" },
                { value: "locker", name: "Casillero" },
              ]}
            />
          </div>

          <div className="flex flex-1">
            {consigneeType === "locker" ? (
              <BoxAccountSearch
                selected={formik.values.boxAccount ?? undefined}
                shipperAccount={formik.values.shipper ?? undefined}
                error={
                  formik.touched.consignee && formik.errors.consignee
                    ? formik.errors.consignee
                    : ""
                }
                setBoxAccount={async (boxAccount, consignee, businessUnit) => {
                  const validations = await validateAccount(
                    consignee,
                    countries
                  );

                  formik.setValues({
                    ...formik.values,
                    boxAccount,
                    accountBillTo: consignee,
                    consignee,
                    buConsignee: businessUnit,
                    consigneeAddress: businessUnit.location,
                    deliveryType: DeliveryType.AT_OFFICE,
                    paymentMode: PaymentMode.CREDIT,
                  });

                  if (!!consignee && validations.error) {
                    setOpenConsigneeEditModal(true);
                  }
                }}
              />
            ) : whitelist.length > 0 ? (
              <div className="flex flex-1 flex-col">
                <div className="relative w-full" id={`input-whitelist`}>
                  <FormGroupSearch
                    label=""
                    error={
                      formik.touched.consignee && formik.errors.consignee
                        ? formik.errors.consignee
                        : ""
                    }
                    value={whitelistSearch}
                    disabled={!formik.values.shipper}
                    placeholder="Buscar destinatario permitido..."
                    optionGroups={[
                      {
                        title: "Destinatarios Permitidos",
                        options: whitelist,
                      },
                    ]}
                    onChange={(event) => setWhitelistSearch(event.target.value)}
                    filter={(option, value) =>
                      filterDestination(value, option.client, option.location)
                    }
                    onChangeFocus={(focus) => {
                      if (!focus && formik.values.consignee) {
                        setWhitelistSearch(
                          formatName(formik.values.consignee.accountFullName)
                        );
                      }
                    }}
                    onSelectOption={async (destination) => {
                      const validations = await validateAccount(
                        destination.client,
                        countries
                      );

                      formik.setValues({
                        ...formik.values,
                        buConsignee: destination.businessUnit,
                        consignee: destination.client,
                        consigneeAddress: destination.location,
                        deliveryType: destination.homeDelivery
                          ? DeliveryType.AT_HOME
                          : DeliveryType.AT_OFFICE,
                      });

                      if (!!destination.client && validations.error) {
                        setOpenConsigneeEditModal(true);
                      }
                    }}
                    RenderOption={({ option }) => (
                      <WhitelistOption {...option} />
                    )}
                  />
                </div>
              </div>
            ) : (
              <AccountSearchFavoriteHistoric
                title=""
                shipperId={formik.values.shipper?.id}
                selected={formik.values.consignee ?? undefined}
                disabled={!formik.values.shipper}
                error={
                  formik.touched.consignee && formik.errors.consignee
                    ? formik.errors.consignee
                    : ""
                }
                placeholder="Buscar destinatario..."
                onSelectClient={async (client) => {
                  const validations = await validateAccount(client, countries);

                  formik.setFieldValue("consignee", client);

                  if (!!client && validations.error) {
                    setOpenConsigneeEditModal(true);
                  }
                }}
                onSelectDestination={async (destination) => {
                  let businessUnit: BusinessUnitInterface;

                  if (destination.deliveryTypeID === DeliveryType.AT_OFFICE) {
                    businessUnit = businessUnits.find(
                      (bu) => bu.code === destination.buCode
                    )!;
                  } else {
                    businessUnit = await getBusinessUnitByTerritory(
                      destination.longitude,
                      destination.latitude
                    ).then((response) => response.model!);
                  }

                  formik.setValues({
                    ...formik.values,
                    buConsignee: businessUnit,
                    consignee: await getAccount(destination.consigneeAccountID),
                    consigneeAddress: {
                      name: destination.addressName,
                      address: destination.addressLine1,
                      reference: destination.addressLandMark,
                      postalCode: destination.postalCode,
                      coordinates: {
                        lat: destination.latitude,
                        lng: destination.longitude,
                      },
                    },
                    deliveryType: destination.deliveryTypeID,
                  });
                }}
                openCreationModal={() => {
                  setOpenConsigneeCreationModal(true);
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* Search destination */}
      <div
        className={classNames(
          !!formik.values.shipper &&
            !!formik.values.consignee &&
            !formik.values.boxAccount &&
            whitelist.length === 0
            ? "flex"
            : "hidden"
        )}
      >
        <div className="flex flex-1 2xl:flex-row flex-col gap-4">
          <div className="flex flex-1 flex-col">
            <div className="flex items-center justify-between mb-2">
              <label className="block text-m font-medium  text-gray-900 truncate">
                Destino
              </label>

              <FormSimpleRadioGroup
                horizontal
                name="locationType"
                disabled={
                  !formik.values.shipper ||
                  !!formik.values.boxAccount ||
                  !formik.values.consignee
                }
                selected={formik.values.deliveryType}
                onSelectOption={(option) =>
                  formik.setValues({
                    ...formik.values,
                    consigneeAddress: null,
                    buConsignee: null,
                    deliveryType: option,
                    paymentMode: PaymentMode.CONTADO,
                  })
                }
                options={[
                  { value: DeliveryType.AT_OFFICE, name: "Tienda" },
                  { value: DeliveryType.AT_HOME, name: "Domicilio" },
                  {
                    value: DeliveryType.INTERNATIONAL,
                    name: "Internacional",
                  },
                ]}
              />
            </div>

            <div className="flex">
              {formik.values.deliveryType === DeliveryType.AT_OFFICE && (
                <BusinessUnitSearch
                  selected={formik.values.buConsignee ?? undefined}
                  error={
                    formik.touched.buConsignee && formik.errors.buConsignee
                      ? formik.errors.buConsignee
                      : ""
                  }
                  disabled={
                    !formik.values.shipper ||
                    !!formik.values.boxAccount ||
                    !formik.values.consignee
                  }
                  setSelectedLocation={(businessUnit) =>
                    formik.setValues({
                      ...formik.values,
                      buConsignee: businessUnit,
                      consigneeAddress: businessUnit.location,
                    })
                  }
                />
              )}

              {formik.values.deliveryType === DeliveryType.AT_HOME && (
                <AddressSearch
                  businessUnits={businessUnits}
                  selected={formik.values.consigneeAddress ?? undefined}
                  region={AutocompleteRegion.TEALCA}
                  error={
                    formik.touched.consigneeAddress &&
                    formik.errors.consigneeAddress
                      ? formik.errors.consigneeAddress
                      : ""
                  }
                  disabled={
                    !formik.values.shipper || !!formik.values.boxAccount
                  }
                  setSelectedLocation={(location, buConsignee) =>
                    formik.setValues({
                      ...formik.values,
                      buConsignee: buConsignee ?? null,
                      consigneeAddress: location,
                    })
                  }
                />
              )}

              {formik.values.deliveryType === DeliveryType.INTERNATIONAL && (
                <AddressSearch
                  businessUnits={businessUnits}
                  selected={formik.values.consigneeAddress ?? undefined}
                  region={AutocompleteRegion.INTERNATIONAL}
                  error={
                    formik.touched.consigneeAddress &&
                    formik.errors.consigneeAddress
                      ? formik.errors.consigneeAddress
                      : ""
                  }
                  disabled={
                    !formik.values.shipper || !!formik.values.boxAccount
                  }
                  setSelectedLocation={(location, buConsignee) =>
                    formik.setValues({
                      ...formik.values,
                      buConsignee: buConsignee ?? null,
                      consigneeAddress: location,
                    })
                  }
                />
              )}
            </div>
          </div>

          <div
            className={classNames(
              "flex flex-1 flex-col",
              formik.values.deliveryType === DeliveryType.AT_OFFICE && "hidden"
            )}
          >
            <FormTextInput
              value={formik.values.consigneeAddress?.reference ?? ""}
              onChange={(event) =>
                formik.setFieldValue("consigneeAddress", {
                  ...formik.values.consigneeAddress,
                  reference: event.target.value,
                })
              }
              name="reference"
              label="Punto de Referencia"
              disabled={!formik.values.consigneeAddress}
            />
          </div>
        </div>
      </div>

      {/* Select pay modality */}
      <div
        className={classNames(
          (!!formik.values.shipper &&
            !!formik.values.consigneeAddress &&
            !formik.values.boxAccount &&
            shipperAuth === AccountAuthorization.NO_AUTHORIZED) ||
            shipperAuth !== AccountAuthorization.NO_AUTHORIZED
            ? "flex"
            : "hidden"
        )}
      >
        <div className="flex flex-1 xl:flex-row flex-col gap-4">
          <div className="flex flex-1 flex-col gap-4">
            {(shipperAuth !== AccountAuthorization.AUTHORIZED ||
              !!formik.values.boxAccount) && (
              <FormSimpleRadioGroup
                horizontal
                name="paymentMode"
                selected={formik.values.paymentMode}
                options={paymentModes}
                label="Modalidad de pago"
                onSelectOption={(paymentMode) =>
                  formik.setFieldValue("paymentMode", paymentMode)
                }
                disabled={
                  !formik.values.shipper ||
                  !formik.values.consigneeAddress ||
                  !!formik.values.boxAccount ||
                  formik.values.deliveryType === DeliveryType.INTERNATIONAL
                }
              />
            )}

            {shipperAuth === AccountAuthorization.AUTHORIZED &&
              !formik.values.boxAccount && (
                <FormSimpleRadioGroup
                  label="Modalidad de pago"
                  horizontal
                  name="paymentMode-with-authAccount"
                  selected={
                    !!formik.values.accountBillTo?.id &&
                    formik.values.paymentMode === PaymentMode.CREDIT
                      ? formik.values.accountBillTo.id
                      : formik.values.paymentMode ?? undefined
                  }
                  options={paymentModesWithAuth.map((option) => {
                    const value = option.value;
                    if (typeof value !== "object") {
                      return {
                        name: option.name,
                        value: value,
                      };
                    } else {
                      return {
                        name: option.name,
                        value: value.id,
                      };
                    }
                  })}
                  disabled={false}
                  onSelectOption={handlePaymentModeChange}
                />
              )}
          </div>
        </div>
      </div>

      <hr className="border-gray-300 my-6" />

      {/* Pieces */}
      <div className="flex flex-1 flex-col">
        <ShipmentPiecesDetails
          id="shipment-pieces-details"
          service={formik.values.service ?? ShipmentService.STANDARD}
          typeDisabled={
            formik.values.deliveryType === DeliveryType.INTERNATIONAL ||
            consigneeType === "locker"
          }
          paymentMode={formik.values.paymentMode ?? PaymentMode.COD}
          pieces={formik.values.pieces ?? []}
          pieceCategories={pieceCategories}
          error={!!formik.errors.pieces}
          setServiceType={(type) => formik.setFieldValue("service", type)}
          serviceOptions={availableServiceTypes}
          setPieces={(pieces) => formik.setFieldValue("pieces", pieces)}
        />
      </div>

      <hr className="border-gray-300 my-6" />

      {/* Observations */}
      <div className="flex flex-1 flex-col">
        <FormTextAreaInput
          rows={4}
          maxLength={192}
          value={formik.values.observations ?? ""}
          onChange={(event) =>
            formik.setFieldValue("observations", event.target.value)
          }
          name="observations"
          label="Observaciones"
          className="resize-none"
        />
      </div>

      <hr className="border-gray-300 my-6" />

      {/* Items */}
      <div className="relative">
        <div
          className={classNames(
            "absolute top-0 right-2",
            isLoading === 0 && "hidden"
          )}
        >
          <LoadingIcon size="1.5rem" />
        </div>

        <ShipmentItemTable
          editable
          total={formik.values.total}
          items={formik.values.items ?? []}
          declaredValue={formik.values.pieces?.reduce(
            (acc, piece) => acc + piece.value,
            0
          )}
          availableItems={shipmentItems.filter(
            (item) =>
              !item.mandatory &&
              !(formik.values.items ?? []).some((i) => i.id === item.id)
          )}
          setItems={(items) =>
            formik.setFieldValue(
              "items",
              items.sort((a, b) => a.order - b.order)
            )
          }
        />
      </div>

      <hr className="border-gray-300 my-6" />

      {/* Discount */}
      <div className="flex flex-col sm:items-center xl:items-start sm:gap-12 sm:flex-row">
        <div className="flex flex-col xl:flex-row">
          <div>
            <FormLabel
              fieldName={"Descuento Tienda"}
              htmlFor="discount"
              fieldNameClassname="mt-2 w-44"
            />
          </div>

          <div style={{ width: "17rem" }}>
            <FormTextInput
              id="discount"
              name="discount"
              error={formik.errors.discount}
              value={formik.values.discount ?? ""}
              placeholder="Porcentaje de descuento"
              onChange={(event) => {
                const setValue = (value: string) => {
                  event.target.value = value;
                };
                if (!formatFloat(event.target.value, setValue)) return;

                formik.setFieldValue("discount", event.target.value);
              }}
            />
            <p
              className={classNames(
                "text-sm text-gray-500 ml-2 mt-1",
                formik.errors.discount && "hidden"
              )}
            >
              Máximo {maxDiscount}%
            </p>
          </div>
        </div>

        <div className="w-full sm:w-auto mt-4 lg:mt-0">
          <AddButton
            disabled={
              (!!formik.touched.discount && !!formik.errors.discount) ||
              !Number(formik.values.discount)
            }
            onClick={() => {
              const mainService = formik.values.items?.find(
                (item) => item.id === "10"
              );
              if (!mainService || !formik.values.items) return;

              const value =
                Math.trunc(
                  -(formik.values.discount ?? 0) * mainService.rate.value
                ) / 100;

              // TODO: Delegate rate calculations to backend by using rate service
              const discount: ItemInterface = {
                ...discountItem,
                rate: {
                  value,
                  distance: 0,
                  tierCode: "",
                  tierName: "",
                  isPercentage: false,
                  iva: +(
                    (-(formik.values.discount ?? 0) *
                      mainService.rate.value *
                      0.16) /
                    100
                  ).toFixed(2),
                  ipostel:
                    formik.values.pieces &&
                    formik.values.pieces.reduce((acc, piece) => {
                      return acc + piece.weight;
                    }, 0) < 30
                      ? +(
                          (-(formik.values.discount ?? 0) *
                            mainService.rate.value *
                            0.08) /
                          100
                        ).toFixed(2)
                      : 0,
                },
              };
              const index = formik.values.items.findIndex(
                (item) => item.id === discount.id
              );

              if (index !== undefined && index >= 0) {
                const items = [...formik.values.items];
                items[index] = discount;
                formik.setFieldValue("items", items);
              } else {
                const items = [...formik.values.items, discount];
                items.sort((a, b) => a.order - b.order);
                formik.setFieldValue("items", items);
              }
            }}
          />
        </div>
      </div>

      {/* Shipper Creation modal */}
      <CreateClientModal
        openModal={openShipperCreationModal}
        setOpenModal={setOpenShipperCreationModal}
        setSelectedItem={(client) =>
          formik.setValues({
            ...formik.values,
            shipper: client,
            consignee: null,
            consigneeAddress: null,
            boxAccount: null,
            buConsignee: null,
            deliveryType: DeliveryType.AT_OFFICE,
          })
        }
      />

      {/* Consignee Creation modal */}
      <CreateClientModal
        openModal={openConsigneeCreationModal}
        setOpenModal={setOpenConsigneeCreationModal}
        setSelectedItem={(client) => formik.setFieldValue("consignee", client)}
      />

      {/* Shipper Update modal */}
      {formik.values.shipper && (
        <UpdateClientModal
          openModal={openShipperEditModal}
          selectedItem={formik.values.shipper}
          setOpenModal={setOpenShipperEditModal}
          setSelectedItem={(client) => formik.setFieldValue("shipper", client)}
        />
      )}

      {/* Consignee Update modal */}
      {formik.values.consignee && (
        <UpdateClientModal
          openModal={openConsigneeEditModal}
          selectedItem={formik.values.consignee}
          setOpenModal={setOpenConsigneeEditModal}
          setSelectedItem={(client) =>
            formik.setFieldValue("consignee", client)
          }
        />
      )}
    </div>
  );
};

interface ShipmentEditProps {
  id: string;
  shipmentNumber: string;
  initialValues: ShipmentFormValue;
}
export const ShipmentEdit: FC = () => {
  const navigate = useNavigate();
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user);
  const { initialValues, id, shipmentNumber } = useLocation()
    .state as ShipmentEditProps;
  const maxDiscount = useAppSelector((state) => state.inmutable.maxDiscount);
  const applicationID = useAppSelector(
    (state) => state.inmutable.appData.applicationID
  );

  const validationSchema = Yup.object().shape({
    shipper: Yup.object().required("El remitente es requerido"),
    consignee: Yup.object().required("El destinatario es requerido"),
    consigneeAddress: Yup.object().required("La dirección es requerida"),
    items: Yup.array()
      .required("Los items son requeridos")
      .min(1, "Los items son requeridos"),
    pieces: Yup.array()
      .required("Las piezas son requeridas")
      .min(1, "Las piezas son requeridas"),
    service: Yup.string().required("El servicio es requerido"),
    paymentMode: Yup.string().required("La modalidad de pago es requerida"),
    deliveryType: Yup.string().required("El tipo de entrega es requerido"),
    discount: Yup.string().test(
      "max-discount",
      `El descuento no puede ser mayor a ${maxDiscount}%`,
      (value) => {
        if (!value) return true;

        return Number(value) <= maxDiscount;
      }
    ),
  });

  const handleUpdateShipment = async (
    values: ShipmentFormValue,
    formikHelpers: FormikHelpers<ShipmentFormValue>
  ) => {
    const shipment: ShipmentInterface = {
      id,
      shipmentNumber,
      shipper: values.shipper!,
      consignee: values.consignee!,
      buSource: user.businessUnit!,
      deliveryType: values.deliveryType!,
      consigneeAddress: values.consigneeAddress!,
      buConsignee: values.buConsignee!,
      pieces: values.pieces,
      items: values.items,
      boxAccount: values.boxAccount ?? undefined,
      paymentMode: values.paymentMode!,
      observations: values.observations ?? "",
      service: values.service!,
      total: values.total,
      status: ShipmentStatus.ACTIVE,
    };

    const response = await updateShipment(
      shipment,
      true,
      true,
      user?.user ?? undefined,
      applicationID
    );

    if (!response) {
      alertService.error("Error al actualizar la guía");
      return;
    } else {
      navigate(`/shipments/${shipmentNumber}`);
    }
  };

  return (
    <main className="lg:pl-72 pb-32">
      {/* Header */}
      <div className="py-8 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Editar Guía
        </header>
      </div>

      {/* Body */}
      <HorizontalPadding>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdateShipment}
        >
          {(formik) => {
            return (
              <div className="flex flex-1 flex-col gap-8">
                {/* Resume */}
                <div className="flex flex-1 flex-col p-6 bg-white rounded-md border">
                  <div className="flex w-full">
                    <div className="gap-8 grid w-full grid-cols-2">
                      {/* Shipper */}
                      <div className="flex flex-1 flex-col">
                        <div className="flex flex-col xl:flex-row xl:gap-4 xl:items-center">
                          <p className="font-semibold">Remitente:</p>
                          <p className="truncate">
                            {formatName(
                              formik.values.shipper?.accountFullName ?? ""
                            )}
                          </p>
                        </div>

                        <ClientDetails
                          client={formik.values.shipper ?? undefined}
                        />
                      </div>

                      {/* Consignee */}
                      <div className="flex flex-1 flex-col">
                        <div className="flex flex-col xl:flex-row xl:gap-4 xl:items-center">
                          <p className="font-semibold">Destinatario:</p>
                          <p className="truncate">
                            {formatName(
                              formik.values.consignee?.accountFullName ?? ""
                            )}
                          </p>
                        </div>

                        <ClientDetails
                          client={formik.values.consignee ?? undefined}
                        />
                      </div>
                    </div>

                    <div
                      className="flex flex-1 flex-col h-full"
                      style={{ minWidth: "5rem", alignSelf: "flex-start" }}
                    >
                      <p className="text-gray-500 text-base font-semibold text-right">
                        Total
                      </p>
                      <p className="text-gray-500 text-base leading-tight text-right">
                        {currencyExchangeText(
                          formik.values.total,
                          exchanges,
                          "USD"
                        )}
                      </p>
                      <p className="text-gray-500 text-base leading-tight text-right">
                        {currencyExchangeText(
                          formik.values.total,
                          exchanges,
                          "BS"
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-1 flex-col pt-4">
                    {/* buSource */}
                    <div className="flex flex-1 flex-col">
                      <div className="flex items-start gap-4">
                        <p className="font-semibold mr-2">Origen:</p>
                        <div className="flex flex-col mt-0.5">
                          <p className="text-sm">
                            {user.businessUnit?.code} -{" "}
                            {user.businessUnit?.location.name}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Location */}
                    <div className="flex flex-1 flex-col mt-2">
                      <div className="flex items-start gap-4">
                        <p className="font-semibold">Destino:</p>
                        <div className="flex flex-col mt-0.5">
                          {formik.values.boxAccount && (
                            <p className="text-sm">
                              Casillero{" "}
                              {formik.values.boxAccount.boxAccountCode}
                            </p>
                          )}

                          {formik.values.buConsignee && (
                            <p className="text-sm">
                              {formik.values.buConsignee.code} -{" "}
                              {formik.values.buConsignee.location?.name}
                            </p>
                          )}

                          <p className="text-xs">
                            {formik.values.consigneeAddress?.name}
                            {" - "}
                            {formik.values.consigneeAddress?.address}{" "}
                            {formik.values.consigneeAddress?.postalCode
                              ? `(código postal: ${formik.values.consigneeAddress?.postalCode})`
                              : ""}
                          </p>
                          <p className="text-xs">
                            {formik.values.consigneeAddress?.reference}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Form */}
                <div className="flex flex-1 flex-col p-6 bg-white rounded-md border">
                  <Form formik={formik} />
                </div>

                {/* Actions */}
                <div className="flex w-full justify-between items-center">
                  <SecondaryButton
                    className="w-40"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancelar
                  </SecondaryButton>

                  <PrimaryButton
                    className="w-40"
                    type="submit"
                    onClick={formik.submitForm}
                  >
                    Actualizar
                  </PrimaryButton>
                </div>
              </div>
            );
          }}
        </Formik>
      </HorizontalPadding>
    </main>
  );
};
