import api from "./api";
import axios from "axios";
import {
  GetShipmentItemsRequestDTO,
  GetTierRequestDto,
  ItemDTO,
  ShipmentDTO,
  StoreShipmentInterface,
  TierDto,
} from "../interfaces/Dtos";
import { UserInterface } from "../interfaces/UserInterface";
import {
  Nullable,
  PaymentMode,
  DeliveryType,
  ItemInterface,
  ShipmentStatus,
  ShipmentService,
  ShipmentInterface,
  LocationInterface,
  PreShipmentInterface,
  BusinessUnitInterface,
  ShipmentResumeInterface,
  ResponseInterface,
  PieceInterface,
  PieceCategoryInterface,
  ShipmentTracking,
} from "../interfaces";
import { PreShipmentDTO } from "../interfaces/Dtos/PreShipmentDTO";
import { ItemsRateDTO } from "../interfaces/Dtos/ItemsRateDTO";

const baseURLAPI = process.env.REACT_APP_API_HOST;
const baseURLJSON = process.env.REACT_APP_API_HOST_JSON_SERVER; // For dev environnment run json-server --watch ./data.json --port 8000

export const getShipment = async (
  shipmentNumber: string
): Promise<Nullable<ShipmentInterface>> => {
  const uri = `${baseURLAPI}/v2/Shipment?ShipmentNumber=${shipmentNumber}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      const dto: ShipmentDTO = response.data.model;

      const shipment: ShipmentInterface = {
        id: dto.shipmentHeaderID,
        shipmentNumber: dto.shipmentNumber,
        service: dto.serviceID,
        paymentMode: dto.paymentModeID,
        deliveryType: dto.deliveryTypeID,
        lastBU: dto.lastBU,
        lastBUDate: dto.lastBUDate,
        observations: dto.observations,
        status: dto.statusID,
        createdAt: dto.creationDate,
        updatedAt: dto.updateDate,
        total: dto.totalAmount,
        declaredValue: dto.declaratedValue?.toString(),
        accountSiteID: dto.accountSiteID,
        substituteFor: dto.substituteFor,
        substitutedBy: dto.substitutedBy,
        totalChargedWeight: dto.totalChargedWeight,
        totalPhysicalWeight: dto.totalPhysicalWeight,
        totalDimensionalWeight: dto.totalDimensionalWeight,
        deliveryDate: dto.deliveryDate,
        deliveryDistance: dto.deliveryDistance,
        deliveryObservation: dto.deliveryObservation,
        deliveryCreationUser: dto.deliveryCreationUser,
        totalPostalTaxBaseCurr: dto.totalPostalTaxBaseCurr,
        document: !!dto.document
          ? {
              documentId: dto.document.documentID,
              documentNumber: dto.document.documentNumber,
              documentTypeID: dto.document.documentTypeID,
              status: dto.document.statusID,
              urlDocument: dto.document.urlDocument,
              creationDate: dto.document.creationDate,
              accountName: dto.document.accountName,
              totalAmountBaseCurr: dto.document.totalAmountBaseCurr,
              balanceAmountBaseCurr: dto.document.balanceAmountBaseCurr,
            }
          : undefined,

        shipper: {
          id: dto.shipper.accountID,
          accountCode: dto.shipper.accountCode,
          taxIdentificationTypeCode: dto.shipper.taxIDentificationTypeCode,
          taxIdentificationTypeID: dto.shipper.taxIdentificationTypeID,
          abreviationName: dto.shipper.abreviationName,
          identificationNumber: dto.shipper.identificationNumber,
          accountFullName: dto.shipper.accountFullName,
          listAccountPhone: [
            {
              phoneID: "",
              countryID: dto.shipper.listAccountPhone[0].countryID,
              accountID: dto.shipper.accountID,
              phoneTypeID: 1,
              phoneNumber: dto.shipper.listAccountPhone[0].phoneNumber,
              countryPhoneAccessCode: null,
            },
          ],
          listAccountEmail: [
            {
              emailID: "",
              accountID: dto.shipper.accountID,
              emailTypeID: 1,
              email: dto.shipper.listAccountEmail[0].email,
            },
          ],
          listAuthorizingAccount: [],
        },

        consignee: {
          id: dto.consignee.accountID,
          accountCode: dto.consignee.accountCode,
          taxIdentificationTypeCode: dto.consignee.taxIDentificationTypeCode,
          taxIdentificationTypeID: dto.consignee.taxIdentificationTypeID,
          abreviationName: dto.consignee.abreviationName,
          identificationNumber: dto.consignee.identificationNumber,
          accountFullName: dto.consignee.accountFullName,
          listAccountPhone: [
            {
              phoneID: "",
              countryID: dto.consignee.listAccountPhone[0].countryID,
              accountID: dto.consignee.accountID,
              phoneTypeID: 1,
              phoneNumber: dto.consignee.listAccountPhone[0].phoneNumber,
              countryPhoneAccessCode: null,
            },
          ],
          listAccountEmail: [
            {
              emailID: "",
              accountID: dto.consignee.accountID,
              emailTypeID: 1,
              email: dto.consignee.listAccountEmail[0].email,
            },
          ],
          listAuthorizingAccount: [],
        },

        accountBillTo: !!dto.accountBillTo
          ? {
              id: dto.accountBillTo.accountID,
              accountCode: dto.accountBillTo.accountCode,
              taxIdentificationTypeCode:
                dto.accountBillTo.taxIDentificationTypeCode,
              taxIdentificationTypeID:
                dto.accountBillTo.taxIdentificationTypeID,
              abreviationName: dto.accountBillTo.abreviationName,
              identificationNumber: dto.accountBillTo.identificationNumber,
              accountFullName: dto.accountBillTo.accountFullName,
              listAccountPhone: [],
              listAccountEmail: [],
              listAuthorizingAccount: [],
            }
          : undefined,

        buSource: {
          id: dto.buSource.buid.toString(),
          code: dto.buSource.buCode,
          name: dto.buSource.buName,
          polygonList: [],
          location: {
            name: dto.buSource.buAreaName,
            code: dto.buSource.buCode,
            address:
              dto.buSource.addressLine1 + ". " + dto.buSource.addressLine2,
            postalCode: dto.buSource.postalCode,
            coordinates: {
              lat: 0,
              lng: 0,
            },
          },
        },

        buConsignee: {
          id: dto.buConsignee.buid.toString(),
          code: dto.buConsignee.buCode,
          name: dto.buConsignee.buName,
          polygonList: [],
          location: {
            name: dto.buConsignee.buAreaName,
            code: dto.buConsignee.buCode,
            address:
              dto.buConsignee.addressLine1 +
              ". " +
              dto.buConsignee.addressLine2,
            postalCode: dto.buConsignee.postalCode,
            coordinates: {
              lat: 0,
              lng: 0,
            },
          },
        },

        consigneeAddress: {
          name: dto.consigneeAddress.addressName,
          code: dto.consigneeAddress.addressShortCode,
          address:
            dto.consigneeAddress.addressLine1 +
            (dto.consigneeAddress.addressLine2
              ? ". " + dto.consigneeAddress.addressLine2
              : ""),
          postalCode: dto.consigneeAddress.postalCode,
          coordinates: {
            lat: dto.consigneeAddress.latitude,
            lng: dto.consigneeAddress.longitude,
          },
          reference: dto.consigneeAddress.landMark,
        },

        pieces:
          dto.shipmentDetails?.map((detail) => ({
            weight: detail.physicalWeight,
            width: detail.width,
            height: detail.height,
            length: detail.lenght,
            value: detail.declaratedValue,
            pieceNumber: detail.pieceNumber,
            category: {
              id: detail.category.categoryID,
              code: detail.category.categoryCode,
              name: detail.category.categoryName,
            },
          })) ?? [],

        items:
          dto.shipmentItems?.map((item, index) => ({
            id: item.itemID.toString(),
            code: item.itemCode,
            name:
              item.couponValue === undefined
                ? item.itemName
                : item.itemName.replace("&&", (-item.couponValue).toString()),
            order: index,
            mandatory: true,
            isItemBase: item.isItemBase,
            couponID: item.couponID,
            rate: {
              value:
                (item.itemID === 31 ||
                  item.itemID === 3010 ||
                  item.itemID === 3020) &&
                !dto.document
                  ? item.pctDiscount
                  : item.totalAmount,
              isPercentage:
                (item.itemID === 31 && item.totalAmount === 0)
                  ? true
                  : false,
              iva: item.taxAmount,
              distance: 0,
              ipostel: 0,
              tierCode: dto.rateTier,
              tierName: dto.tierName,
            },
          })) ?? [],
      };
      return shipment;
    }
  } catch (error) {}

  return null;
};

export const dynamicShipmentSearch = async (
  search: string,
  buCodeSource?: string,
  buCodeConsignee?: string,
  deliveryTypes?: DeliveryType[],
  statuses?: ShipmentStatus[]
): Promise<Nullable<ShipmentResumeInterface[]>> => {
  const deliveryTypesString = deliveryTypes?.join(",");
  const statusesString = statuses?.join(",");

  let uri = `${baseURLAPI}/v2/Shipment/DynamicSearch?`;
  if (search) {
    uri += `Search=${search}&`;
  }
  if (buCodeSource) {
    uri += `buCodeSource=${buCodeSource}&`;
  }
  if (buCodeConsignee) {
    uri += `buCodeConsignee=${buCodeConsignee}&`;
  }
  if (deliveryTypesString) {
    uri += `DeliveryTypeIDList=${deliveryTypesString}&`;
  }
  if (statusesString) {
    uri += `StatusIDList=${statusesString}&`;
  }

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      const result: ShipmentResumeInterface[] = [];

      for (let i = 0; i < response.data.model.length; i++) {
        const dto: ShipmentDTO = response.data.model[i];

        if (
          !!dto.shipper &&
          !!dto.consignee &&
          !!dto.buSource &&
          !!dto.buConsignee &&
          !!dto.consigneeAddress
        ) {
          result.push({
            id: dto.shipmentHeaderID,
            shipmentNumber: dto.shipmentNumber,
            service: dto.serviceID,
            paymentMode: dto.paymentModeID,
            deliveryType: dto.deliveryTypeID,
            lastBU: dto.lastBU,
            observations: dto.observations,
            deliveryDate: dto.deliveryDate,
            deliveryDistance: dto.deliveryDistance,
            deliveryObservation: dto.deliveryObservation,
            deliveryCreationUser: dto.deliveryCreationUser,
            totalPieces: dto.totalPieces,
            totalAmount: dto.totalAmount,
            totalChargedWeight: dto.totalChargedWeight,
            totalPhysicalWeight: dto.totalPhysicalWeight,
            totalDimensionalWeight: dto.totalDimensionalWeight,
            totalPostalTaxBaseCurr: dto.totalPostalTaxBaseCurr,
            accountSiteID: dto.accountSiteID,
            substituteFor: dto.substituteFor,
            substitutedBy: dto.substitutedBy,
            document: !!dto.document
              ? {
                  documentId: dto.document.documentID,
                  documentTypeID: dto.document.documentTypeID,
                  documentNumber: dto.document.documentNumber,
                  status: dto.document.statusID,
                  urlDocument: dto.document.urlDocument,
                  creationDate: dto.document.creationDate,
                  accountName: dto.document.accountName,
                  totalAmountBaseCurr: dto.document.totalAmountBaseCurr,
                  balanceAmountBaseCurr: dto.document.balanceAmountBaseCurr,
                }
              : undefined,
            shipper: {
              id: dto.shipper.accountID,
              taxIdentificationTypeID: dto.shipper.taxIdentificationTypeID,
              identificationNumber: dto.shipper.identificationNumber,
              accountFullName: dto.shipper.accountFullName,
              listAccountEmail: dto.shipper.listAccountEmail,
              listAccountPhone: dto.shipper.listAccountPhone,
            },
            consignee: {
              id: dto.consignee.accountID,
              taxIdentificationTypeID: dto.consignee.taxIdentificationTypeID,
              identificationNumber: dto.consignee.identificationNumber,
              accountFullName: dto.consignee.accountFullName,
              listAccountEmail: dto.shipper.listAccountEmail,
              listAccountPhone: dto.shipper.listAccountPhone,
            },
            accountBillTo: !!dto.accountBillTo
              ? {
                  id: dto.accountBillTo.accountID,
                  taxIdentificationTypeID:
                    dto.accountBillTo.taxIdentificationTypeID,
                  identificationNumber: dto.accountBillTo.identificationNumber,
                  accountFullName: dto.accountBillTo.accountFullName,
                }
              : undefined,
            buSource: {
              id: dto.buSource.buid,
              code: dto.buSource.buCode,
              name: dto.buSource.buName,
              location: {
                id: dto.buSource.locationID,
                name: dto.buSource.buAreaName,
                code: dto.buSource.buCode,
              },
            },
            buConsignee: {
              id: dto.buConsignee.buid,
              code: dto.buConsignee.buCode,
              name: dto.buConsignee.buName,
              location: {
                id: dto.buConsignee.locationID,
                name: dto.buConsignee.buAreaName,
                code: dto.buConsignee.buCode,
              },
            },
            consigneeAddress: {
              addressName: dto.consigneeAddress.addressName,
              latitude: dto.consigneeAddress.latitude,
              longitude: dto.consigneeAddress.longitude,
              reference: dto.consigneeAddress.landMark,
            },
            pieces: dto.shipmentDetails.map((detail) => ({
              weight: detail.physicalWeight,
              width: detail.width,
              height: detail.height,
              length: detail.lenght,
              value: detail.declaratedValue,
              pieceNumber: detail.pieceNumber,
              category: {
                id: detail.category.categoryID,
                code: detail.category.categoryCode,
                name: detail.category.categoryName,
              },
            })),

            items: dto.shipmentItems.map((item, index) => ({
              id: item.itemID.toString(),
              code: item.itemCode,
              name: item.itemName,
              order: index,
              mandatory: true,
              isItemBase: false,
              rate: {
                value: item.pctDiscount,
                isPercentage: true,
                iva: item.taxAmount,
                ipostel: 0,
                distance: 0,
                tierCode: dto.rateTier,
                tierName: dto.tierName,
              },
            })),
            status: dto.statusID,
            createdAt: dto.creationDate,
            updatedAt: dto.updateDate,
          });
        }
      }

      return result;
    }
  } catch (error) {}

  return null;
};

export const getShipments = async (): Promise<
  Nullable<ShipmentInterface[]>
> => {
  const uri = `${baseURLJSON}/shipments`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

export const getShipmentsByBUConsignee = async (
  businessUnit?: BusinessUnitInterface
): Promise<Nullable<ShipmentInterface[]>> => {
  const uri = `${baseURLJSON}/shipments`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data.filter((shipment: ShipmentInterface) => {
        return !businessUnit || shipment.buConsignee?.id === businessUnit.id;
      });
    }
  } catch (error) {}

  return null;
};

export const getShipmentsByBUSource = async (
  businessUnit?: BusinessUnitInterface
): Promise<Nullable<ShipmentInterface[]>> => {
  const uri = `${baseURLJSON}/shipments`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data.filter((shipment: ShipmentInterface) => {
        return !businessUnit || shipment.buSource.id === businessUnit.id;
      });
    }
  } catch (error) {}

  return null;
};

export const getHistoricalShipments = async (
  search?: string,
  deliveryTypes?: DeliveryType[],
  statuses?: ShipmentStatus[]
): Promise<Nullable<ShipmentInterface[]>> => {
  const uri = `${baseURLJSON}/shipments`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data.filter((shipment: ShipmentInterface) => {
        return (
          (!statuses ||
            !statuses.length ||
            statuses.some((status) => status === shipment.status)) &&
          (!deliveryTypes ||
            !deliveryTypes.length ||
            deliveryTypes.includes(shipment.deliveryType)) &&
          (!search ||
            shipment.id?.toString().toLowerCase().includes(search) ||
            shipment.shipper.accountFullName
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            shipment.consignee.accountFullName
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            shipment.consigneeAddress.name
              .toLowerCase()
              .includes(search.toLocaleLowerCase()))
        );
      });
    }
  } catch (error) {}

  return null;
};

interface ShipmentSaveResponse {
  shipmentHeaderID: string;
  shipmentNumber: string;
}
export const saveShipment = async (
  shipment: ShipmentInterface,
  user?: UserInterface,
  applicationID?: number
): Promise<ResponseInterface<ShipmentSaveResponse>> => {
  const mainItem = shipment.items?.find(
    (item) => item.isItemBase
  )!;
  const srmItem = shipment.items?.find((item) => item.id === "20");

  const body = JSON.stringify({
    ServiceID: shipment.service?.toString(),
    PaymentModeID: shipment.paymentMode?.toString(),
    DeliveryTypeID: shipment.deliveryType?.toString(),
    PreShipmentHeaderID: shipment.preShipmentHeaderID,
    RateTier: shipment.items[0]?.rate.tierCode,
    BoxAccountCode: shipment.boxAccount?.boxAccountCode,
    buCodeSource: shipment.buSource.code,
    buCodeConsignee: shipment.buConsignee?.code,
    DeliveryDistance: shipment.deliveryDistance,
    ShipperAccountID: shipment.shipper.id,
    ShipperCountryID: shipment.shipper.countryID,
    ShipperName: shipment.shipper.accountFullName,
    ShipperTaxIdentTypeID: shipment.shipper.taxIdentificationTypeID,
    ShipperTaxIdentTypeCode: shipment.shipper.taxIdentificationTypeCode,
    ShipperIdentificationNumber: shipment.shipper.identificationNumber,
    ShipperAddress: shipment.buSource.location.address,
    ShipperAddressName: shipment.buSource.location.name,
    ShipperPostalCode: shipment.buSource.location.postalCode,
    ShipperPhoneCode:
      shipment.shipper.listAccountPhone[0]?.countryPhoneAccessCode,
    ShipperPhone: shipment.shipper.listAccountPhone[0]?.phoneNumber,
    ShipperEmail: shipment.shipper.listAccountEmail[0]?.email,
    ConsigneeName: shipment.consignee.accountFullName,
    ConsigneeTaxIdentTypeID: shipment.consignee.taxIdentificationTypeID,
    ConsigneeTaxIdentTypeCode: shipment.consignee.taxIdentificationTypeCode,
    ConsigneeIdentificationNumber: shipment.consignee.identificationNumber,
    ConsigneeAccountID: shipment.consignee.id,
    ConsigneeAddress: shipment.consigneeAddress.address,
    ConsigneeAddressName: shipment.consigneeAddress.name,
    ConsigneePostalCode: shipment.consigneeAddress.postalCode,
    ConsigneePhoneCode:
      shipment.consignee.listAccountPhone[0]?.countryPhoneAccessCode,
    ConsigneePhone: shipment.consignee.listAccountPhone[0]?.phoneNumber,
    ConsigneeEmail: shipment.consignee.listAccountEmail[0]?.email,
    AccountBillToID: shipment.accountBillTo?.id,
    AccountSiteID: shipment.accountSiteID,
    ApplicationID: applicationID,
    TotalPieces: shipment.pieces.length,
    Observations: shipment.observations,
    CreationUser: user?.userLogin,
    IsSafeKeeping: shipment.pieces.reduce((acc, p) => acc + p.value, 0) > 0,
    IsToPickup: shipment.isToPickup,
    ConsigneeAddressLandMark: shipment.consigneeAddress.reference,
    ShipperLatitude: shipment.buSource?.location.coordinates.lat,
    ShipperLongitude: shipment.buSource?.location.coordinates.lng,
    ConsigneeLatitude: shipment.consigneeAddress.coordinates.lat,
    ConsigneeLongitude: shipment.consigneeAddress.coordinates.lng,
    ContentDescription: shipment.pieces.map((piece, index) => ({
      PieceNumber: index + 1,
      ProductCode: piece.category.code,
    })),
    ShipmentDetail: shipment.pieces.map((piece, index) => ({
      PieceNumber: index + 1,
      Height: piece.height,
      Width: piece.width,
      Lenght: piece.length,
      PhysicalWeight: piece.weight,
      DeclaratedValue: piece.value,
    })),
    ShipmentItem: shipment.items
      .filter((item) => !item.mandatory)
      .map((item) => ({
        ItemID: item.id,
        TaxAmount: item.rate.iva,
        PctDiscount:
          item.id === "31" || item.id === "3010"
            ? (item.rate.value * 100) / mainItem.rate.value
            : item.id === "3020"
            ? (item.rate.value * 100) / (srmItem?.rate.value ?? 0)
            : 0,
        TotalAmount: item.rate.value,
        isPercentage: item.id === "31" ? true : false,
      })),
    LastBU: shipment.buSource.code,
    StatusID: shipment.status.toString(),
    // Fixed
    CurrencyID: 2, //In case change necessary, CurrencyID: 1
    EmailType: "010",
    WeightUnitID: "10",
    AccountTypeID: "3",
    ShipperCity: "CCS",
    PackageTypeID: "10",
    ConsigneeCity: "CCS",
    ShipperCountry: "VE",
    ShippingMethodID: "10",
    ConsigneeCountry: "VE",
    ConsigneeCountryID: "236",
    shipperCountryCodeIso: "VEN",
    DeclaratedValueCurrency: "VES",
  });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/v2/Shipment`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error creando la guía",
    errorMessage: "Hubo un error creando la guía",
  };
};

export const updateShipment = async (
  shipment: Partial<ShipmentInterface>,
  updateItems: boolean,
  updateDetailTable: boolean,
  user?: UserInterface,
  applicationID?: number
): Promise<ResponseInterface<boolean>> => {
  const mainItem = shipment.items?.find(
    (item) => 10 <= +item.id && +item.id < 19
  )!;
  const srmItem = shipment.items?.find((item) => item.id === "20")!;

  const body = JSON.stringify({
    UpdateUser: user?.userLogin,

    ShipmentHeaderID: shipment.id,
    ShipmentNumber: shipment.shipmentNumber,

    UpdateItems: updateItems,
    UpdateDetailTable: updateDetailTable,
    UpdateDescriptionTable: false,

    ServiceID: shipment.service?.toString(),
    PaymentModeID: shipment.paymentMode?.toString(),
    DeliveryTypeID: shipment.deliveryType?.toString(),
    RateTier: shipment.items?.[0]?.rate.tierCode,
    BoxAccountCode: shipment.boxAccount?.boxAccountCode,
    BuCodeSource: shipment.buSource?.code,
    BuCodeConsignee: shipment.buConsignee?.code,
    DeliveryDistance: shipment.deliveryDistance,

    ShipperAccountID: shipment.shipper?.id,
    ShipperCountryID: shipment.shipper?.countryID,
    ShipperName: shipment.shipper?.accountFullName,
    ShipperTaxIdentTypeID: shipment.shipper?.taxIdentificationTypeID,
    ShipperIdentificationNumber: shipment.shipper?.identificationNumber,
    ShipperAddress: shipment.buSource?.location.address,
    ShipperAddressName: shipment.buSource?.location.name,
    ShipperPostalCode: shipment.buSource?.location.postalCode,
    ShipperPhone: shipment.shipper?.listAccountPhone[0]?.phoneNumber,
    ShipperEmail: shipment.shipper?.listAccountEmail[0]?.email,

    ConsigneeName: shipment.consignee?.accountFullName,
    ConsigneeTaxIdentTypeID: shipment.consignee?.taxIdentificationTypeID,
    ConsigneeIdentificationNumber: shipment.consignee?.identificationNumber,
    ConsigneeAccountID: shipment.consignee?.id,
    ConsigneeAddress: shipment.consigneeAddress?.address,
    ConsigneeAddressName: shipment.consigneeAddress?.name,
    ConsigneePostalCode: shipment.consigneeAddress?.postalCode,
    ConsigneePhone: shipment.consignee?.listAccountPhone[0]?.phoneNumber,
    ConsigneeEmail: shipment.consignee?.listAccountEmail[0]?.email,

    AccountBillToID: shipment.accountBillTo?.id,
    AccountSiteID: shipment.accountSiteID,
    ApplicationID: applicationID,
    TotalPieces: shipment.pieces?.length,
    Observations: shipment.observations,
    IsSafeKeeping: shipment.pieces
      ? (shipment.pieces?.reduce((acc, p) => acc + p.value, 0) ?? 0) > 0
      : null,
    ConsigneeAddressLandMark: shipment.consigneeAddress?.reference,
    ShipperLatitude: shipment.buSource?.location.coordinates.lat,
    ShipperLongitude: shipment.buSource?.location.coordinates.lng,
    ConsigneeLatitude: shipment.consigneeAddress?.coordinates.lat,
    ConsigneeLongitude: shipment.consigneeAddress?.coordinates.lng,
    ContentDescription:
      shipment.pieces?.map((piece, index) => ({
        PieceNumber: index + 1,
        ProductCode: piece.category.code,
      })) ?? [],
    ShipmentDetail:
      shipment.pieces?.map((piece, index) => ({
        PieceNumber: index + 1,
        Height: piece.height,
        Width: piece.width,
        Lenght: piece.length,
        PhysicalWeight: piece.weight,
        DeclaratedValue: piece.value,
      })) ?? [],
    ShipmentItem:
      shipment.items
        ?.filter((item) => !item.mandatory && !item.isItemBase)
        .map((item) => ({
          ItemID: item.id,
          TaxAmount: item.rate.iva,
          PctDiscount:
            item.id === "31" || item.id === "3010"
              ? (item.rate.value * 100) / mainItem.rate.value
              : item.id === "3020"
              ? (item.rate.value * 100) / srmItem.rate.value
              : 0,
          TotalAmount: item.rate.value,
          isPercentage: item.id === "31" ? true : false,
        })) ?? [],
    LastBU: shipment.buSource?.code,
    StatusID: shipment.status?.toString(),
    ConsigneeCountryID: "236",
    ShippingMethodID: "10",
  });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/v2/Shipment`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: `Hubo un error actualizando la guía ${shipment.shipmentNumber}`,
    errorMessage: `Hubo un error actualizando la guía ${shipment.shipmentNumber}`,
  };
};

export const updateShipmentStatus = async (
  status: ShipmentStatus,
  shipmentId?: string,
  shipmentNumber?: string,
  user?: UserInterface
): Promise<ResponseInterface<boolean>> => {
  const body = JSON.stringify({
    ShipmentHeaderID: shipmentId,
    ShipmentNumber: shipmentNumber,
    StatusID: status,
    UpdateUser: user?.userLogin,
    UpdateDate: new Date().toISOString(),
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/v2/Shipment/Status`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {}

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error al actualizar la guías",
  };
};

export const getPreShipment = async (
  preShipmentNumber: string,
  defaultCategory: PieceCategoryInterface
): Promise<ResponseInterface<PreShipmentInterface>> => {
  const uri = `${baseURLAPI}/v2/PreShipment?PreShipmentNumber=${preShipmentNumber}&NumDays=7`;

  try {
    const response = await api.get(uri);

    if (response.status === 200 && !response.data.didError) {
      const dto: PreShipmentDTO = response.data.model;

      const avrgDeclaratedValue =
        (dto.totalDeclaratedValue ?? 0) /
        (dto.totalPieces ?? dto.shipmentDetails?.length ?? 1);

      const preShipment: PreShipmentInterface = {
        id: dto.preShipmentHeaderID,
        preShipmentNumber: dto.preShipmentNumber,
        service: dto.serviceID,
        accountSiteID: dto.accountSiteID,
        paymentMode: dto.paymentModeID,
        deliveryType: dto.deliveryTypeID,
        observations: dto.observations,
        status: dto.statusID,
        createdAt: dto.creationDate,
        updatedAt: dto.updateDate,
        creationUser: dto.creationUser,
        isToPickup: dto.isToPickup,

        shipper: {
          id: dto.shipper.accountID,
          accountCode: dto.shipper.accountCode,
          taxIdentificationTypeCode: dto.shipper.taxIDentificationTypeCode,
          taxIdentificationTypeID: dto.shipper.taxIdentificationTypeID,
          abreviationName: dto.shipper.abreviationName,
          identificationNumber: dto.shipper.identificationNumber,
          accountFullName: dto.shipper.accountFullName,
          listAccountPhone: [
            {
              phoneID: "",
              countryID: dto.shipper.listAccountPhone[0].countryID,
              accountID: dto.shipper.accountID,
              phoneTypeID: 1,
              phoneNumber: dto.shipper.listAccountPhone[0].phoneNumber,
              countryPhoneAccessCode: null,
            },
          ],
          listAccountEmail: [
            {
              emailID: "",
              accountID: dto.shipper.accountID,
              emailTypeID: 1,
              email: dto.shipper.listAccountEmail[0].email,
            },
          ],
          listAuthorizingAccount: [],
        },

        consignee: {
          id: dto.consignee.accountID,
          accountCode: dto.consignee.accountCode,
          taxIdentificationTypeCode: dto.consignee.taxIDentificationTypeCode,
          taxIdentificationTypeID: dto.consignee.taxIdentificationTypeID,
          abreviationName: dto.consignee.abreviationName,
          identificationNumber: dto.consignee.identificationNumber,
          accountFullName: dto.consignee.accountFullName,
          listAccountPhone: [
            {
              phoneID: "",
              countryID: dto.consignee.listAccountPhone[0].countryID,
              accountID: dto.consignee.accountID,
              phoneTypeID: 1,
              phoneNumber: dto.consignee.listAccountPhone[0].phoneNumber,
              countryPhoneAccessCode: null,
            },
          ],
          listAccountEmail: [
            {
              emailID: "",
              accountID: dto.consignee.accountID,
              emailTypeID: 1,
              email: dto.consignee.listAccountEmail[0].email,
            },
          ],
          listAuthorizingAccount: [],
        },

        accountBillTo: !!dto.accountBillTo
          ? {
              id: dto.accountBillTo.accountID,
              accountCode: dto.accountBillTo.accountCode,
              taxIdentificationTypeCode:
                dto.accountBillTo.taxIDentificationTypeCode,
              taxIdentificationTypeID:
                dto.accountBillTo.taxIdentificationTypeID,
              abreviationName: dto.accountBillTo.abreviationName,
              identificationNumber: dto.accountBillTo.identificationNumber,
              accountFullName: dto.accountBillTo.accountFullName,
              listAccountPhone: [],
              listAccountEmail: [],
              listAuthorizingAccount: [],
            }
          : undefined,

        buSource: {
          id: dto.buSource.buid.toString(),
          code: dto.buSource.buCode,
          name: dto.buSource.buName,
          polygonList: [],
          location: {
            name: dto.buSource.buAreaName,
            code: dto.buSource.buCode,
            address:
              dto.buSource.addressLine1 + ". " + dto.buSource.addressLine2,
            postalCode: dto.buSource.postalCode,
            coordinates: {
              lat: 0,
              lng: 0,
            },
          },
        },

        buConsignee: {
          id: dto.buConsignee.buid.toString(),
          code: dto.buConsignee.buCode,
          name: dto.buConsignee.buName,
          polygonList: [],
          location: {
            name: dto.buConsignee.buAreaName,
            code: dto.buConsignee.buCode,
            address:
              dto.buConsignee.addressLine1 +
              ". " +
              dto.buConsignee.addressLine2,
            postalCode: dto.buConsignee.postalCode,
            coordinates: {
              lat: 0,
              lng: 0,
            },
          },
        },

        consigneeAddress: {
          name: dto.consigneeAddress.addressName,
          code: dto.consigneeAddress.addressShortCode,
          address:
            dto.consigneeAddress.addressLine1 +
            ". " +
            dto.consigneeAddress.addressLine2,
          postalCode: dto.consigneeAddress.postalCode,
          coordinates: {
            lat: dto.consigneeAddress.latitude,
            lng: dto.consigneeAddress.longitude,
          },
        },

        pieces: dto.shipmentDetails?.map((detail) => ({
          weight: detail.physicalWeight,
          width: detail.width,
          height: detail.height,
          length: detail.lenght,
          value: Math.max(avrgDeclaratedValue, detail.declaratedValue ?? 0),
          category: detail.category.categoryID
            ? {
                id: detail.category.categoryID,
                code: detail.category.categoryCode,
                name: detail.category.categoryName,
              }
            : defaultCategory,
        })),
      };

      return {
        message: "",
        didError: false,
        errorMessage: "",
        model: preShipment,
      };
    } else if (response.data.didError) {
      return {
        message: "",
        didError: true,
        errorMessage: response.data.errorMessage,
        model: null,
      };
    }
  } catch (error) {}

  return {
    message: "",
    didError: true,
    errorMessage: "Hubo un error al obtener la pre-guía",
    model: null,
  };
};

export const getShipmentItems = async (
  request: GetShipmentItemsRequestDTO
): Promise<ResponseInterface<ItemInterface[]>> => {
  const uri = `${baseURLAPI}/SearchItemList`;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const body = JSON.stringify(request);

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return {
        ...response.data,
        model: response.data.model.map((item: ItemDTO) => ({
          id: item.itemID.toString(),
          code: item.itemCode,
          name: item.itemDescription.replace(
            "&&",
            Math.abs(item.couponValue ?? 0).toString()
          ),
          order: item.itemOrder,
          couponID: item.couponID,
          mandatory: item.mandatory,
          isItemBase: item.isItemBase,
          isItemShipment: item.isItemShipment,
          rate: {
            value: 0,
            isPercentage: false,
            tax: 0,
          },
        })),
      };
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error al obtener los servicios del envío.",
    errorMessage: "Hubo un error al obtener los servicios del envío.",
  };
};

export const getShipmentRates = async (
  service: ShipmentService,
  paymentMode: PaymentMode,
  deliveryType: DeliveryType,
  buCodeSource: string,
  buCodeConsignee: string,
  shipperAccountID: string | undefined,
  consigneeAccountID: string | undefined,
  accountBillToID: string | undefined,
  sourceLocation: LocationInterface | undefined,
  consigneeLocation: LocationInterface,
  items: ItemInterface[],
  pieces: PieceInterface[],
  srm: boolean,
  saleDate: string,
  applicationID: number,
): Promise<ResponseInterface<ItemsRateDTO>> => {
  const uri = `${baseURLAPI}/GetShipmentRateV2Async`;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const body = JSON.stringify({
    srm,
    countryID: 236,
    serviceID: service,
    paymentModeID: paymentMode,
    DeliveryTypeID: deliveryType,
    shippingMethodID: 10,
    buCodeConsignee,
    buCodeSource,
    shipperAccountID,
    consigneeAccountID,
    accountBillToID,
    applicationID,
    latitudeSource: sourceLocation?.coordinates.lat ?? 0,
    longitudeSource: sourceLocation?.coordinates.lng ?? 0,
    latitudeDestination: consigneeLocation.coordinates.lat,
    longitudeDestination: consigneeLocation.coordinates.lng,
    saleDate,
    itemList: items.map((item) => ({
      itemID: item.id,
      itemName: item.name,
      isItemBase: item.isItemBase,
      couponID: item.couponID,
      isPercentage: item.rate.isPercentage,
      rateResult: {
        rate: item.rate.value,
      },
    })),
    pieceList: pieces.map((piece) => ({
      weight: piece.weight,
      width: piece.width,
      height: piece.height,
      length: piece.length,
      declaratedValue: piece.value,
    })),
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return {
        message: "",
        didError: false,
        errorMessage: "",
        model: {
          distance: response.data.distance,
          chargedWeight: response.data.chargedWeight,
          physicalWeight: response.data.physicalWeight,
          dimensionalWeight: response.data.dimensionalWeight,
          items: items.map((item) => {
            const itemRate = response.data.items.find(
              (rate: any) => +rate.itemID === +item.id
            );
            return {
              ...item,
              // TODO: Refactor mapping to include amounts in base currency and tusd
              rate: {
                value: itemRate ? itemRate.rateResult.amountBaseCurr : 0,
                iva: itemRate ? itemRate.rateResult.itemTaxAmountBaseCurr : 0,
                ipostel: itemRate
                  ? itemRate.rateResult.postalTaxAmountBaseCurr
                  : 0,
                isPercentage: itemRate
                  ? itemRate.rateResult.isPercentage
                  : false,
                distance: itemRate ? itemRate.rateResult.distance : 0,
                tierCode: itemRate ? itemRate.rateTier : "",
                tierName: itemRate ? itemRate.tierName : "",
              },
            };
          }),
        },
      };
    }
  } catch (error) {
    const response = (error as any)?.response?.data;

    if (typeof response?.errorMessage === "string") {
      return response;
    }
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error al obtener los precios de los servicios.",
    errorMessage: "Hubo un error al obtener los precios de los servicios.",
  };
};

export interface ShipmentDeclaredDocumentRequestDto {
  shipmentNumber?: string;
  shipmentHeaderID?: string;
}
export interface FileDocument {
  file: File;
  fileName: string;
}
export const getAllDeclaredShipmentDocuments = async (
  shipmentDocumentList: ShipmentDeclaredDocumentRequestDto[],
  documentId?: string
): Promise<Nullable<FileDocument>> => {
  const uri = `${baseURLAPI}/v1/Shipment/DownloadAllDocumentDeclared`;
  const body = JSON.stringify({
    List: shipmentDocumentList,
  });
  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      return {
        file: response.data,
        fileName: !!documentId
          ? "AllShipmentDocument_" + documentId + ".pdf"
          : "AllShipmentDocument.pdf",
      };
    }
  } catch (error) {}
  return null;
};

export const getDeclaredShipmentDocument = async (
  shipmentDocument: ShipmentDeclaredDocumentRequestDto
): Promise<Nullable<FileDocument>> => {
  const uri = `${baseURLAPI}/v1/Shipment/DownloadDocumentDeclared`;
  const body = JSON.stringify(shipmentDocument);
  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });

    if (response.status === 200) {
      let filename =
        "ShipmentDocument_" + shipmentDocument.shipmentNumber + ".pdf"; // Default filename
      return {
        file: response.data,
        fileName: filename,
      };
    }
  } catch (error) {}
  return null;
};

export const getShipmentTracking = async (
  shipmentNumber: string,
  service: ShipmentService,
  buSourceCode: string
): Promise<Nullable<ShipmentTracking[]>> => {
  const uri = `${baseURLAPI}/Tracking?shipment=${shipmentNumber}&service=${service}&buCodeSource=${buSourceCode}&isDetail=false`;
  try {
    const response = await api.get(uri);

    if (response.status === 200 && response.data[0].tracking.length > 0) {
      return response.data[0].tracking;
    }
  } catch (error) {}
  return null;
};

export const searchShipmentFullText = async (
  queryStr: string,
  bUCodeSource?: string,
  bUCodeConsignee?: string,
  shipmentDateFrom?: string,
  shipmentDateTo?: string,
  statusIDList?: ShipmentStatus[],
  deliveryTypeIDList?: DeliveryType[],
  paymentModeIDList?: PaymentMode[],
  serviceIDList?: ShipmentService[]
): Promise<ResponseInterface<StoreShipmentInterface[]>> => {
  const uri = `${baseURLAPI}/v2/Shipment/SearchShipmentsFulltext`;

  const body = JSON.stringify({
    queryStr,
    bUCodeSource: bUCodeSource ?? null,
    bUCodeConsignee: bUCodeConsignee ?? null,
    shipmentDateFrom: shipmentDateFrom ?? null,
    shipmentDateTo: shipmentDateTo ?? null,
    statusIDList,
    deliveryTypeIDList,
    paymentModeIDList,
    serviceIDList,
  });

  try {
    const response = await axios.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    return (error as any).response.data;
  }
};

export const searchShipmentPendingPOD = async (
  buCode: string
): Promise<ResponseInterface<StoreShipmentInterface[]>> => {
  const uri = `${baseURLAPI}/v2/Shipment/SearchShipmentPendingPOD?BUCode=${buCode}`;

  try {
    const response = await axios.get(uri);

    return response.data;
  } catch (error) {
    return (error as any).response.data;
  }
};

export const getTier = async (
  request: GetTierRequestDto
): Promise<ResponseInterface<TierDto>> => {
  const uri = `${baseURLAPI}/GetTier`;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const body = JSON.stringify(request);

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error al obtener el tier.",
    errorMessage: "Hubo un error al obtener el tier.",
  };
};

export const getWeights = async (
  countryID: number,
  shippingMethodID: number,
  shipperAccountID: string | undefined,
  serviceID: number | undefined,
  paymentModeID: number | undefined,
  pieces: PieceInterface[]
): Promise<
  ResponseInterface<{
    chargedWeight: number;
    physicalWeight: number;
    dimensionalWeight: number;
  }>
> => {
  const uri = `${baseURLAPI}/GetChargedWeight`;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const body = JSON.stringify({
    countryID,
    shippingMethodID,
    shipperAccountID,
    serviceID,
    paymentModeID,
    pieceList: pieces.map((piece) => ({
      weight: piece.weight,
      width: piece.width,
      height: piece.height,
      length: piece.length,
    })),
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error al obtener los pesos.",
    errorMessage: "Hubo un error al obtener los pesos.",
  };
};

export const getDeliveryDistance = async (
  sourceLatitude: number,
  sourceLongitude: number,
  destinationLatitude: number,
  destinationLongitude: number
): Promise<
  ResponseInterface<{
    deliveryDistance: number;
  }>
> => {
  const uri = `${baseURLAPI}/GetDeliveryDistance`;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const body = JSON.stringify({
    sourceLatitude,
    sourceLongitude,
    destinationLatitude,
    destinationLongitude,
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error al obtener la distancia de entrega.",
    errorMessage: "Hubo un error al obtener la distancia de entrega.",
  };
};

export const getAccountHistoricalShipments = async (
  accountID: string,
  queryStr: string,
  byShipper: boolean,
  byConsignee: boolean,
  byAccountBillTo: boolean,
): Promise<ResponseInterface<StoreShipmentInterface[]>> => {
  const uri = `${baseURLAPI}/v2/Shipment/HistoricalShipments?` +
    `QueryStr=${queryStr}&AccountID=${accountID}&ByShipper=${byShipper}&ByConsignee=${byConsignee}&ByAccountBillTo=${byAccountBillTo}`;

  try {
    const response = await axios.get(uri, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    return (error as any).response.data;
  }
};
