import { FC, useContext, useMemo, useState } from "react";
import classNames from "classnames";
import ShipmentDetailsModal from "./ShipmentDetailsModal";
import { StoreShipmentInterface } from "../../interfaces/Dtos";
import {
  formatName,
  currencyExchangeText,
  useCurrencyExchanges,
  shipmentStatusFormat,
  paymentModeFormat,
  documentStatusFormat,
} from "../../utils";
import { useAppSelector } from "../../store/hooks";
import moment from "moment";
import { DocumentType, PaymentMode, ShipmentStatus } from "../../interfaces";
import { PendingShipmentsContext } from "../../contexts/PendingShipmentsContext";
import { LinkText } from "../Buttons";

interface PendingShipmentItemProps {
  index: number;
  showLocation?: boolean;
  showDelivery?: boolean;
  deliveryInColumn?: boolean;
  shipment: StoreShipmentInterface;
  pendingShipments: StoreShipmentInterface[];
}

const PendingShipmentItem: FC<PendingShipmentItemProps> = ({
  index,
  shipment,
  showLocation,
  showDelivery,
  deliveryInColumn,
  pendingShipments,
}) => {
  const businessUnits = useAppSelector(
    (state) => state.inmutable.businessUnits
  );
  const pendingShipmentsContext = useContext(PendingShipmentsContext);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const user = useAppSelector((state) => state.user);

  const exchanges = useCurrencyExchanges();
  const [openModal, setOpenModal] = useState(false);

  const canChangeToDelivery = useMemo(() => {
    console.log("shipment", shipment);
    return (
      shipment.statusID === ShipmentStatus.ACTIVE &&
      shipment.buCodeConsignee === user.businessUnit?.code &&
      shipment.lastBU === user.businessUnit?.code &&
      shipment.paymentModeID === PaymentMode.COD &&
      shipment.storePickup === "1" &&
      !shipment.documentNumber
    );
  }, [shipment, user]);

  const handleClick = async () => {
    setOpenModal(true);
  };

  const handleDeliveryClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    const lastBU = businessUnits.find(x => x.code === shipment.lastBU)!;
    const buName = `${lastBU.code} - ${lastBU.location.name}`;
    const message = `Hola ${shipment.consigneeName},\nTe escribimos desde la ` +
      `oficina Tealca ${buName}, ya que tenemos la guía ` +
      `${shipment.shipmentNumber} disponible para su entrega. Si lo ` +
      "deseas, podemos ofrecerte el servicio de entrega a domicilio. " +
      "Indícanos tu ubicación para gestionar la entrega allí.";

    const url = `https://wa.me/+58${shipment.consigneePhone}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <tr
      className={classNames(
        "cursor-pointer hover:bg-gray-100",
        index % 2 === 0 && "bg-gray-50"
      )}
      onClick={handleClick}
    >
      <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left">
        <p className="text-indigo-600">{shipment.shipmentNumber}</p>
        <p>
          <span className="text-gray-400">Creada: </span>{" "}
          {new Date(shipment.creationDate)
            .toLocaleDateString("es-VE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })
            .replace("a", "AM")
            .replace("p", "PM")
            .slice(0, -4)}
        </p>
        <p>{paymentModeFormat(shipment.paymentModeID)}</p>
        <p className="">{shipmentStatusFormat(shipment.statusID)}</p>
      </td>

      <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left truncate">
        <p>{formatName(shipment.shipperName)}</p>
        <p className="text-gray-400 font-medium">
          {
            taxIdentificationTypes.find(
              (t) =>
                t.taxIdentificationTypeId ===
                shipment.shipperTaxIdentificationTypeID
            )?.abreviationName
          }
          {shipment.shipperIdentificationNumber}
        </p>
        <p className="text-gray-400 font-medium">{shipment.shipperPhone}</p>
        <p>
          {shipment.buCodeSource} - {shipment.sourceBUAreaName}
        </p>
      </td>

      <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left truncate">
        <p>{formatName(shipment.consigneeName)}</p>
        <p className="text-gray-400 font-medium">
          {
            taxIdentificationTypes.find(
              (t) =>
                t.taxIdentificationTypeId ===
                shipment.consigneeTaxIdentificationTypeID
            )?.abreviationName
          }
          {shipment.consigneeIdentificationNumber}
        </p>
        <p className="text-gray-400 font-medium">{shipment.consigneePhone}</p>
        <p>
          {shipment.buCodeConsignee} - {shipment.consigneeBUAreaName}
        </p>
      </td>

      <td className="text-xs px-4 py-4 font-normal w-auto text-gray-500 truncate">
        <p className={classNames(deliveryInColumn && "hidden")}>
          Entrega:{" "}
          <span className="font-bold">
            {shipment.storePickup === "1" ? "En Tienda" : "A Domicilio"}
          </span>
        </p>
        {canChangeToDelivery && !deliveryInColumn && (
          <LinkText text="Disponible para Extra Domicilio" onClick={handleDeliveryClick} />
        )}
        <p>
          Nº de piezas:{" "}
          <span className="font-bold">{shipment.totalPieces}</span>
        </p>
        <p>
          Peso:{" "}
          <span className="font-bold">{shipment.totalChargedWeight} Kg</span>
        </p>
      </td>

      <td className="text-left text-xs px-4 py-4 font-semibold w-auto text-gray-500 truncate">
        <p
          className={classNames(
            "font-bold",
            !shipment.documentStatusID && "hidden"
          )}
        >
          <span className="font-normal">
            {shipment.documentTypeID === DocumentType.INVOICE
              ? "Factura: "
              : "Orden: "}
          </span>
          {!!shipment.documentStatusID
            ? shipment.documentNumber
            : "Sin Factura Asociada"}
        </p>
        <p className="font-bold">
          {!!shipment.documentStatusID
            ? documentStatusFormat(shipment.documentStatusID)
            : "Sin Factura Asociada"}
        </p>

        <p className={classNames("hidden")}>
          Monto de la guía:{" "}
          <span className="font-bold">
            {currencyExchangeText(
              shipment.totalAmountBaseCurr,
              exchanges,
              "USD"
            )}
          </span>
        </p>
      </td>

      <td
        className={classNames(
          "text-left text-xs px-4 py-4 font-normal w-auto text-gray-500 truncate",
          !showLocation && "hidden"
        )}
      >
        <p className="font-bold">
          {shipment.statusID === ShipmentStatus.DELIVERED
            ? "Entregada a cliente"
            : `${shipment.lastBU} - ${shipment.lbuBUAreaName}`}
        </p>
        <p
          className={classNames(
            shipment.statusID === ShipmentStatus.DELIVERED && "hidden"
          )}
        >
          Desde:{" "}
          <span className="font-bold">
            {new Date(shipment.lastBUDate)
              .toLocaleDateString("es-VE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
              .replace("a", "AM")
              .replace("p", "PM")
              .slice(0, -4)}
          </span>
        </p>
      </td>

      {deliveryInColumn && (
        <td className="text-center text-sm px-4 py-4 font-semibold w-auto text-gray-500 truncate">
          {moment().diff(moment(shipment.lastBUDate), "days")}
        </td>
      )}

      {showDelivery && (
        <td className="text-xs px-4 py-4 font-semibold w-auto text-gray-500 min-w-[15rem] max-w-[25rem]">
          <div
            className="overflow-hidden"
            style={{
              lineClamp: "3",
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
            }}
          >
            <p>
              {shipment.storePickup === "1"
                ? "En Tienda"
                : shipment.consigneeAddress}
            </p>
            {canChangeToDelivery && <LinkText text="Disponible para Extra Domicilio" onClick={handleDeliveryClick} />}
            <p
              className={classNames(
                !shipment.consigneeAddressLandmark && "!hidden"
              )}
            >
              <span className="font-normal">Punto de ref:</span>{" "}
              {shipment.consigneeAddressLandmark}
            </p>
          </div>
        </td>
      )}

      <td className="h-0 w-0" onClick={(e) => e.stopPropagation()}>
        <ShipmentDetailsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          shipmentNumber={shipment.shipmentNumber}
          shipments={pendingShipments}
          onReload={() => pendingShipmentsContext.setRefresh(Math.random())}
        />
      </td>
    </tr>
  );
};

export default PendingShipmentItem;
